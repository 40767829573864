<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveSubscription"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <section class="section">
        <div class="container">
          <table v-if="subscription" class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Channel</td>
                <td width="250">{{ subscription.ChannelName }}</td>
              </tr>
              <tr>
                <td>Package</td>
                <td>{{ subscription.PackageName }}</td>
              </tr>
              <tr>
                <td>Period</td>
                <td>
                  {{ subscription.Month | getMonthName }}
                  {{ subscription.Year }}
                </td>
              </tr>
              <tr>
                <td>Monthly fixed fee</td>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static">&euro;</a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="subscription.Price"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <table v-if="subscription" class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Meetingspace fee</td>
                <td width="250">
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static">&euro;</a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="subscription.SeatFeeMS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Meetingspace max. fee seats</td>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static">&euro;</a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="subscription.MaxSeatFeeTotalMS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <table v-if="subscription" class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Workspace fee</td>
                <td width="250">
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static">&euro;</a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="subscription.SeatFeeWS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Workspace max. fee seats</td>
                <td>
                  <div class="field has-addons">
                    <p class="control">
                      <a class="button is-static">&euro;</a>
                    </p>
                    <p class="control">
                      <input
                        type="text"
                        v-model.number="subscription.MaxSeatFeeTotalWS"
                        class="input"
                      />
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <table v-if="subscription" class="table is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Free trial</td>
                <td width="75" class="has-text-right">
                  <input
                    type="checkbox"
                    v-model="subscription.IsTrial"
                    id="freeTrial"
                    class="checkbox"
                  />
                </td>
              </tr>

              <tr>
                <td>Recurring</td>
                <td width="75" class="has-text-right">
                  <input
                    type="checkbox"
                    v-model="subscription.IsRecurring"
                    id="freeTrial"
                    class="checkbox"
                  />
                </td>
              </tr>

              <tr>
                <td>Cancelled</td>
                <td width="75" class="has-text-right">
                  <input
                    type="checkbox"
                    v-model="subscription.IsCancelled"
                    id="freeTrial"
                    class="checkbox"
                  />
                </td>
              </tr>

              <tr>
                <td>Billable</td>
                <td width="75" class="has-text-right">
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      v-model="subscription.SendInvoice"
                      id="freeTrial"
                      class="checkbox"
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </template>
  </ui-component-modal>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import { mapState, mapMutations } from 'vuex'
import subscriptionProvider from '@/providers/subscription'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('subscriptionStore', [
      'subscription',
      'subscriptionsPageState',
    ]),
  },

  created() {},

  methods: {
    ...mapMutations('subscriptionStore', [
      'setSubscription',
      'setSubscriptionsPageState',
    ]),

    saveSubscription() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        subscriptionProvider.methods
          .updateSubscription(this.subscription)
          .then((response) => {
            if (response.status === 200) {
              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Subscription updated!',
              })

              self.onClickCancel()
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
